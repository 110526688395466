





























































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import { Form } from "element-ui";
import {
  listForTestPaper,
  standardAnswer,
  updateForTestPaper,
} from "@/api/testPaper";
import { TestAnswer } from "./index";
import JsonEditor from "@/components/JsonEditor/index.vue";
import EnPredScore from "./component-en-pred-score.vue";
import EnPcheScore from "./component-en-pche-score.vue";
import EnPqanScore from "./component-en-pqan-score.vue";
import EnRetellScore from "./component-en-retell-score.vue";
import ObjectiveScore from "./component-objective-score.vue";
import * as _ from "lodash";
import * as ResApi from "@/api/examFileResource";
import TestPaperOriginalFileDialog from "./test-paper-original-file-dialog.vue";
import BatchObjectiveQtySelOneDialog from "./batch-objective-qtyselone-dialog.vue";

const jsonData = "[]"; //'[{"items":[{"market_type":"forexdata","symbol":"XAUUSD"},{"market_type":"forexdata","symbol":"UKOIL"},{"market_type":"forexdata","symbol":"CORN"}],"name":""},{"items":[{"market_type":"forexdata","symbol":"XAUUSD"},{"market_type":"forexdata","symbol":"XAGUSD"},{"market_type":"forexdata","symbol":"AUTD"},{"market_type":"forexdata","symbol":"AGTD"}],"name":"贵金属"},{"items":[{"market_type":"forexdata","symbol":"CORN"},{"market_type":"forexdata","symbol":"WHEAT"},{"market_type":"forexdata","symbol":"SOYBEAN"},{"market_type":"forexdata","symbol":"SUGAR"}],"name":"农产品"},{"items":[{"market_type":"forexdata","symbol":"UKOIL"},{"market_type":"forexdata","symbol":"USOIL"},{"market_type":"forexdata","symbol":"NGAS"}],"name":"能源化工"}]'

@Component({
  name: "TestPaperStandardAnswer",
  components: {
    cardBox,
    JsonEditor,
    EnPredScore,
    EnPcheScore,
    EnPqanScore,
    EnRetellScore,
    ObjectiveScore,
    TestPaperOriginalFileDialog,
    BatchObjectiveQtySelOneDialog,
  },
})
export default class extends Vue {
  private rules = {
    standardScore: [
      { required: true, message: "请输入标准分数", trigger: "blur" },
    ],
    questionId: [{ required: true, message: "请选择题目", trigger: "blur" }],
    displayText: [
      { required: true, message: "请输入标答展示文本", trigger: "blur" },
    ],
    questionCoreType: [
      { required: true, message: "请选择测试类型", trigger: "blur" },
    ],
    requestCoreType: [
      { required: true, message: "请选择测试类型", trigger: "blur" },
    ],
    refText: [{ required: true, message: "请输入参考文本", trigger: "blur" }],
    lm: [{ required: true, message: "参考答案不能为空。", trigger: "blur" }],
    key: [{ required: true, message: "关键词不能为空。", trigger: "blur" }],
    unkey: [{ required: true, message: "错误关键词不能为空。", trigger: "blur" }],
    points: [{ required: true, message: "答案要点不能为空。", trigger: "blur" }],
    standardAnswer:[{ required: true, message: "客观题参考答案不能为空。", trigger: "blur" }]
  };
  private test: any = JSON.parse(jsonData);

  private activeName: string = "baseInfo";
  private answerData: TestAnswer[] = [];
  private selData: TestAnswer = {
    standardAnswerId: "",
    questionId: "",
    questionNo: "",
    questionName: "",
    questionType: "",
    questionCoreType: "",
    id: 0,
    globalIndex: 0,
    displayText: "",
    standardScore: 0,
    standardAnswer: "",
    paperFileName: "",
    requestCoreType: "",
    requestRefText: "",
    requestRank: 0,
    requestPrecision: 0,
    requestRateScale: 0,
    requestObject: {}, //JSON.stringify("{}")
  };

  private doSetDisplayTextFromLmOrRefText() {
    let standardAnswer: string[] = _.get(
      this.selData,
      "standardAnswer",
      ""
    ).split("|");

    let displayText: string = "";
    displayText = _.get(this.selData.requestObject, "lm[0].text", "");
    if (displayText && displayText.search(/\([^\)]+\)/g) >= 0) {
      displayText = "";
    }
    if (!displayText) {
      displayText = _.get(this.selData.requestObject, "key[0].text", "");
    }
    if (!displayText) {
      displayText = _.get(this.selData.requestObject, "refText", "");
    }
    if (!displayText) {
      displayText = standardAnswer.length
        ? standardAnswer[standardAnswer.length - 1]
        : "";
    }
    this.selData.displayText = displayText;
    /*
    this.selData.displayText =
      _.get(this.selData.requestObject, "key[0].text", "") ||
      _.get(this.selData.requestObject, "lm[0].text", "") ||
      _.get(this.selData.requestObject, "refText", "") ||
      (standardAnswer.length
        ? standardAnswer[standardAnswer.length - 1]
        : "") ||
      "";
      */
  }

  private async submitForm() {
    const valid = await (this.$refs.ruleForm as Form).validate();
    if (valid) {
      const res: any = await standardAnswer(this.selData);
      return res.code === 0;
    }
    return false;
  }

  private doSubmitFormClick() {
    this.submitForm()
      .then((result) => {
        if (result) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
        }
      })
      .catch((error) => {
        //错误
      });
  }

  private doBatchSettingQTYOneSelClick() {
    const current = this.selData;
    const pickedItems: TestAnswer[] = [];
    this.answerData.forEach((item) => {
      if (item.questionType.toLowerCase() == "QTYOneSel".toLowerCase()) {
        this.selData = item;
        this.handleQuestionIdChange(this.selData);
        pickedItems.push(item);
      }
    });
    this.selData = current;
    this.handleQuestionIdChange(this.selData);
    if (!pickedItems.length) {
      return;
    }
    const dialog = this.$refs
      .batchObjectiveQtySelOneDialog as BatchObjectiveQtySelOneDialog;
    dialog.show(pickedItems);
  }

  private async handleBatchObjectiveQtySelOneConfirmed(
    standardAnswers: TestAnswer[]
  ) {
    let tasks: any[] = [];
    standardAnswers.forEach((item) => tasks.push(standardAnswer(item)));
    await Promise.all(tasks);
  }

  private doSubmitFormAndNextClick() {
    this.submitForm()
      .then((result) => {
        if (result) {
          const idx = this.answerData.findIndex((item) => {
            return item.standardAnswerId == this.selData.standardAnswerId;
          });
          if (idx >= 0 && idx < this.answerData.length - 1) {
            this.selData = this.answerData[idx + 1];
            this.handleQuestionIdChange(this.selData);
          }
        }
      })
      .catch((error) => {
        //错误
      });
  }

  private doGoBackClick() {
    this.$router.go(-1);
  }

  private async getListForTestPaper() {
    const res = await listForTestPaper({
      testPaperFileName: this.$route.query.fileName as string,
    });
    this.answerData = res.data.items.sort((a: any, b: any) => {
      return a.id < b.id ? -1 : a.id > b.id ? 1 : 0;
    });
    return res;
  }
  private async setUpdateForTestPaper() {
    const resp = await updateForTestPaper({
      testPaperFileName: this.$route.query.fileName as string,
    });
    console.log(resp);
    return resp;
  }

  private async __initStandardAnswers() {
    try {
      await this.setUpdateForTestPaper();
    } finally {
      await this.getListForTestPaper();
    }
  }

  private getTestPaperOriginalFileUrl(): string {
    const baseUrl: string = process.env.VUE_APP_BASE_API || "";
    //const sOriginalFileUrl: string = `/2022_七年级下期中听说试题一_d1f5ac6d4d8431b0fed201b1bf60f4ff.pdf`;
    const sOriginalFileUrl: string = `v1/standardAnswer/download/testPaperOriginalFile`;
    const testPaperFileName: string = this.$route.query.fileName as string;

    const url = `${baseUrl}${
      baseUrl.endsWith("/") ? "" : "/"
    }${sOriginalFileUrl}?testPaperFileName=${testPaperFileName}`;
    //
    console.log(url);
    //
    return url;
  }
  private doDownlodTestPaperOriginalFileClick() {
    try {
      const testPaperFileName: string = this.$route.query.fileName as string;
      const fileName = testPaperFileName.split("\\").pop()?.split(".")[0];
      ResApi.download(
        this.getTestPaperOriginalFileUrl(),
        {},
        `试卷原文_${fileName}.pdf`,
        {
          type: "application/pdf;charset=utf-8",
        }
      );
    } catch (error) {
      //
    }
  }
  private testPaperOriginalFileObjUrl: string = "";
  private async doViewTestPaperOriginalFileClick() {
    try {
      if (!this.testPaperOriginalFileObjUrl) {
        const blob: Blob = await ResApi.downloadAsBlob(
          this.getTestPaperOriginalFileUrl(),
          {
            //testPaperFileName,
          },
          {
            type: "application/pdf;charset=utf-8",
          }
        );
        this.testPaperOriginalFileObjUrl = URL.createObjectURL(blob);
      }
      (
        this.$refs.testPaperOriginalFileDialog as TestPaperOriginalFileDialog
      ).show(this.testPaperOriginalFileObjUrl);
    } catch (error: any) {
      this.$message({
        type: "error",
        message: error.message || error.msg || "下载原文失败。",
      });
    }
  }

  mounted() {
    if (this.$route.query.fileName) {
      this.__initStandardAnswers();
    }
  }

  private displayQuestionTypes(
    areaTags: string[],
    gradeNos: string[]
  ): boolean {
    let result: boolean = true;

    const gradeNo: string = (this.$route.query.gradeNo as string) || "";
    const areaTag: string = (this.$route.query.str02 as string) || "";

    if (parseInt(gradeNo) && gradeNos.length) {
      result = result && gradeNos.some((item) => item == gradeNo);
    }
    if (areaTag && areaTags.length) {
      result = result && areaTags.some((item) => item == areaTag);
    }

    //
    return result;
  }

  private doResetStandardScore() {
    const gradeNo: string = (this.$route.query.gradeNo as string) || "";
    const areaTag: string = (this.$route.query.str02 as string) || "";
    //
    let standardAnswer: number = 0;
    switch (this.selData.questionCoreType.toLowerCase()) {
      case "x1.en.pred.score": {
        //模仿朗读
        standardAnswer = 4;
        break;
      }
      case "x2.en.pche.score": {
        //信息获取 - 听选信息
        standardAnswer = 1;
        break;
      }
      case "x3.en.pqan.score": {
        //信息获取 - 问答题
        standardAnswer = 1;
        break;
      }
      case "x4.en.retell.score": {
        //短文复述
        standardAnswer = 8;
        break;
      }
      case "x5.en.pqan.score": {
        //短文复述
        standardAnswer = 1.5;
        break;
      }
      //高中
      case "y1.en.pred.score": {
        //模仿朗读
        standardAnswer = 20;
        break;
      }
      case "y2.en.pqan.score": {
        //提问
        standardAnswer = 2;
        break;
      }
      case "y3.en.pqan.score": {
        //问答题
        standardAnswer = 2;
        break;
      }
      case "y4.en.retell.score": {
        //短文复述
        standardAnswer = 24;
        break;
      }
      //湖北宜昌初中
      case "x50.en.pche.score": {
        //朗读正确答案
        standardAnswer = 0.5;
        break;
      }
      case "x51": {
        //客观题-听对话选择
        standardAnswer = 1;
        break;
      }
      case "x52": {
        //客观题-一空一词
        standardAnswer = 1;
        break;
      }
      case "x53.en.retell.score": {
        //短文复述
        standardAnswer = 5;
        break;
      }
      case "x54.en.pred.score": {
        //模仿朗读
        standardAnswer = 8;
        break;
      }
      //吉林延边初中
      case "x70": {
        //客观题-听小对话回答问题
        standardAnswer = 1;
        break;
      }
      case "x71": {
        //客观题-听较长对话回答问题
        standardAnswer = 1;
        break;
      }
      case "x72": {
        //客观题-听短文回答问题
        standardAnswer = 1;
        break;
      }
      case "x73.en.pred.score": {
        //朗读短文
        standardAnswer = 3;
        break;
      }
      case "x74.en.pqan.score": {
        //情景问答
        standardAnswer = 1;
        break;
      }
      case "x75.en.retell.score": {
        //话题简述
        standardAnswer = 5;
        break;
      }

      default: {
        standardAnswer = 0;
      }
    }

    this.selData.standardScore = standardAnswer;
  }

  private handleTabClick(tab: any, event: any) {
    switch (tab.name) {
      case "advSetting": {
        setTimeout(() => {
          (this.$refs.jsonEditor as JsonEditor).refresh();
        }, 0);

        break;
      }
    }
    console.log(tab, event);
  }
  private handleQuestionIdChange(value: TestAnswer) {
    const gradeNo: string = (this.$route.query.gradeNo as string) || "";
    const areaTag: string = (this.$route.query.str02 as string) || "";
    //地区数组需与制卷系统保持一至
    const areaGdTags = ["", "广东", "深圳", "珠海", "潮汕"];
    const areaJLTags = ["吉林", "延边"];
    const areaHBxTags = ["河北", "衡阳"];
    const areaHBTags = ["湖北", "宜昌"];

    if (gradeNo && value.questionCoreType == "") {
      switch (parseInt(gradeNo)) {
        case 4:
        case 5:
        case 6: {
          //小学
          if (areaGdTags.some((item) => item == areaTag)) {
            //深圳
            switch (value.questionName) {
              case "1-1":
              case "1-2":
              case "1-3":
              case "1-4":
              case "1-5": {
                value.questionCoreType = "z0";
                break;
              }
              case "2-1":
              case "2-2":
              case "2-3":
              case "2-4":
              case "2-10": {
                value.questionCoreType = "z1";
                break;
              }
              case "3-1": {
                value.questionCoreType = "z2.en.pred.score";
                break;
              }
              case "4-1":
              case "4-2":
              case "4-3":
              case "4-4":
              case "4-5":
              case "4-6": {
                value.questionCoreType = "z3.en.pqan.score";
                break;
              }
              case "5-1": {
                value.questionCoreType = "z4.en.retell.score";
                break;
              }
            }
          }

          this.handleQuestionCoreTypeChange();
          break;
        }
        case 7:
        case 8:
        case 9:
        case 14:
        case 15: {
          //中学
          if (areaGdTags.some((item) => item == areaTag)) {
            //深圳
            switch (value.questionName) {
              case "1-1": {
                value.questionCoreType = "x1.en.pred.score";
                break;
              }
              case "2-1":
              case "2-2":
              case "2-3":
              case "2-4":
              case "2-5":
              case "2-6": {
                value.questionCoreType = "x2.en.pche.score";
                break;
              }
              case "2-7":
              case "2-8":
              case "2-9":
              case "2-10": {
                value.questionCoreType = "x3.en.pqan.score";
                break;
              }
              case "3-1": {
                value.questionCoreType = "x4.en.retell.score";
                break;
              }
              case "3-2-1":
              case "3-2-2": {
                value.questionCoreType = "x5.en.pqan.score";
                break;
              }
            }
          } else if (areaJLTags.some((item) => item == areaTag)) {
            //吉林
            switch (value.questionName) {
              case "1-1":
              case "1-2":
              case "1-3":
              case "1-4":
              case "1-5": {
                value.questionCoreType = "x70";
                break;
              }
              case "2-10":
              case "2-6":
              case "2-7":
              case "2-8":
              case "2-9": {
                value.questionCoreType = "x71";
                break;
              }
              case "3-11":
              case "3-12":
              case "3-13":
              case "3-14":
              case "3-15": {
                value.questionCoreType = "x72";
                break;
              }
              case "4-1": {
                value.questionCoreType = "x73.en.pred.score";
                break;
              }
              case "5-1":
              case "5-2": {
                value.questionCoreType = "x74.en.pqan.score";
                break;
              }
              case "6-1": {
                value.questionCoreType = "x75.en.retell.score";
                break;
              }
            }
          } else if (areaHBTags.some((item) => item == areaTag)) {
            //湖北
            switch (value.questionName) {
              case "1-1":
              case "1-2":
              case "1-3":
              case "1-4":
              case "1-5":
              case "1-6":
              case "1-7":
              case "1-8": {
                value.questionCoreType = "x50.en.pche.score";
                break;
              }
              case "2-10":
              case "2-11":
              case "2-12":
              case "2-13":
              case "2-14":
              case "2-15":
              case "2-16": {
                value.questionCoreType = "x51";
                break;
              }
              case "17":
              case "18":
              case "19":
              case "20":
              case "21": {
                value.questionCoreType = "x52";
                break;
              }
              case "3-2": {
                value.questionCoreType = "x53.en.retell.score";
                break;
              }
              case "4-1": {
                value.questionCoreType = "x54.en.pred.score";
                break;
              }
            }
          } else if (areaHBxTags.some((item) => item == areaTag)) {
            //河北
          }

          this.handleQuestionCoreTypeChange();

          break;
        }
        case 10:
        case 11:
        case 12: {
          //高中
          this.handleQuestionCoreTypeChange();
          break;
        }
      } //switch gradeNo
    }
  }
  handleQuestionCoreTypeChange() {
    const tmpArr: string[] = this.selData.questionCoreType.split(".");
    tmpArr.splice(0, 1);
    this.selData.requestCoreType = tmpArr.join(".");
  }
  handleRequestCoreTypeChange() {
    return;
    switch (this.selData.requestCoreType) {
      case "en.pred.score": {
        if (!_.has(this.selData.requestObject, "refText")) {
          _.merge(this.selData.requestObject, {
            refText: "",
          });
        }
        break;
      }
      case "en.pche.score": {
        if (!_.has(this.selData.requestObject, "lm")) {
          _.merge(this.selData.requestObject, {
            lm: [],
          });
        }
        if (!_.has(this.selData.requestObject, "key")) {
          _.merge(this.selData.requestObject, {
            key: [],
          });
        }
        if (!_.has(this.selData.requestObject, "unkey")) {
          _.merge(this.selData.requestObject, {
            unkey: [],
          });
        }
        break;
      }
      case "en.pqan.score": {
        if (!_.has(this.selData.requestObject, "lm")) {
          _.merge(this.selData.requestObject, {
            lm: [],
          });
        }
        if (!_.has(this.selData.requestObject, "key")) {
          _.merge(this.selData.requestObject, {
            key: [],
          });
        }
        break;
      }
      case "en.retell.score": {
        if (!_.has(this.selData.requestObject, "lm")) {
          _.merge(this.selData.requestObject, {
            lm: [],
          });
        }
        break;
      }
    } //switch
    console.log(`requestObject: ${JSON.stringify(this.selData.requestObject)}`);
  }
}
