






































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ScoreTags from "./component-score-tags.vue";
import * as _ from "lodash";

//2. 信息获得(提供的信息包含 正确和错误答案的)，使用en.pche.score
@Component({
  name: "EnPcheScore",
  components: {
    ScoreTags,
  },
})
export default class extends Vue {
  @Prop({ default: undefined }) requestObject!: any;
  //
  @Watch("requestObject", { immediate: true, deep: true })
  onRequestObjectChange(val: any, oldVal: any) {
    //alert("project");
    if (this.requestObject) {
      console.log(
        `requestObject change: ${JSON.stringify(this.requestObject)}`
      );
      if (!_.has(this.requestObject, "lm")) {
        /*
        _.merge(this.requestObject, {
          lm: [],
        });
        */
        this.$set(this.requestObject, "lm", []);
      }
      if (!_.has(this.requestObject, "key")) {
        /*
        _.merge(this.requestObject, {
          key: [],
        });
        */
        this.$set(this.requestObject, "key", []);
      }
      if (!_.has(this.requestObject, "unkey")) {
        /*
        _.merge(this.requestObject, {
          unkey: [],
        });
        */
        this.$set(this.requestObject, "unkey", []);
      }
    }
  }
  //

  private async doEditLmClick() {
    if (this.requestObject.key && !this.requestObject.key.length) {
      this.$message({
        type: "warning",
        message: "正确答案不能为空。",
      });
      return;
    }
    if (this.requestObject.unkey && !this.requestObject.unkey.length) {
      this.$message({
        type: "warning",
        message: "错误答案不能为空。",
      });
      return;
    }
    if (this.requestObject.lm && !this.requestObject.lm.length) {
      this.$message({
        type: "warning",
        message: "评分选项不能为空。",
      });
      return;
    }
    let tmp: string[] = [];
    tmp.push(
      ...this.requestObject.key.map((item: any) => {
        return item.text;
      })
    );
    tmp.push(
      ...this.requestObject.unkey.map((item: any) => {
        return item.text;
      })
    );
    const s1: string = `(${tmp.join("|")})`;
    let newLm: string[] = [];
    for (let i = 0; i < this.requestObject.lm.length; i++) {
      let s: string = this.requestObject.lm[i].text;
      s = s.replace(/\([^\)]+\)/g, this.requestObject.key[0].text);
      let regEx = new RegExp(`(${this.requestObject.key[0].text})`, "gi");
      s = s.replace(regEx, s1);
      newLm.push(s);
    }
    await this.$confirm(
      `评分选项将替换为：${newLm.join("/")}，是否继续？`,
      "取答案信息格式化选项",
      {
        type: "warning",
      }
    );
    for (let i = 0; i < this.requestObject.lm.length; i++) {
      this.requestObject.lm[i].text = newLm[i];
    } 
  }

  private doAddLmClick() {
    this.$prompt("请输入内容(/n/r)", "格式化文本", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      inputType: "textarea",
      closeOnClickModal: false,
    }).then((result: any) => {
      let text: string = result.value || "";
      if (!text) {
        return;
      }
      let arr: string[] = text.split("\n");
      if (!arr.length) {
        arr = text.split("\r");
      }
      text = `(${arr.join("|")})`;

      const idx = this.requestObject.lm.findIndex((item: { text: string }) => {
        return item.text.toLowerCase() == text.toLowerCase();
      });
      if (idx < 0) {
        this.requestObject.lm.push({ text });
      }
      this.requestObject.lm.splice(0, 0);
    });
  }

  private doAddFromLmClick(prop: string) {
    let text: string =
      this.requestObject.lm.length > 0 ? this.requestObject.lm[0].text : "";
    let arr: any = text.match(/\([^\)]+\)/g);
    let key: string = arr && arr.length > 0 ? arr[0] : "";
    key = key.replace("(", "").replace(")", "");
    let keys: string[] = key.split("|");
    switch (prop) {
      case "key": {
        if (keys.length < 1) {
          return;
        }
        let i = 0;
        const idx = this.requestObject.key.findIndex(
          (item: { text: string }) => {
            return item.text.toLowerCase() == keys[i].toLowerCase();
          }
        );
        if (idx < 0) {
          this.requestObject.key.push({ text: keys[i] });
        }
        this.requestObject.key.splice(0, 0);
        break;
      }
      case "unkey": {
        if (keys.length < 2) {
          return;
        }
        for (let i = 1; i < keys.length; i++) {
          const idx = this.requestObject.unkey.findIndex(
            (item: { text: string }) => {
              return item.text.toLowerCase() == keys[i].toLowerCase();
            }
          );
          if (idx < 0) {
            this.requestObject.unkey.push({ text: keys[i] });
          }
        }
        this.requestObject.unkey.splice(0, 0);
        break;
      }
    }
  }

  handleLmChanged(
    action: string,
    item: { text: string },
    lm: { text: string }[]
  ) {
    return;

    switch (action) {
      case "delete": {
        const idx = this.requestObject.lm.findIndex((element: any) => {
          return element.text == item.text;
        });
        if (idx >= 0) {
          this.requestObject.lm.splice(idx, 1);
        }
        break;
      }
      case "add": {
        this.requestObject.lm.push(item);
      }
    }
    //this.requestObject.lm.splice(0, lm.length);
    //this.requestObject.lm.p
  }
  //
  handleKeyChanged(
    action: string,
    item: { text: string },
    key: { text: string }[]
  ) {
    return;
    switch (action) {
      case "delete": {
        const idx = this.requestObject.key.findIndex((element: any) => {
          return element.text == item.text;
        });
        if (idx >= 0) {
          this.requestObject.key.splice(idx, 1);
        }
        break;
      }
      case "add": {
        this.requestObject.key.push(item);
      }
    }
    //this.requestObject.key.splice(0, key.length);
    //this.requestObject.key.p
  }
  handleUnkeyChanged(
    action: string,
    item: { text: string },
    unkey: { text: string }[]
  ) {
    return;
    switch (action) {
      case "delete": {
        const idx = this.requestObject.unkey.findIndex((element: any) => {
          return element.text == item.text;
        });
        if (idx >= 0) {
          this.requestObject.unkey.splice(idx, 1);
        }
        break;
      }
      case "add": {
        this.requestObject.unkey.push(item);
      }
    }
    //this.requestObject.unkey.splice(0, unkey.length);
    //this.requestObject.unkey.p
  }

  mounted() {
    this.$nextTick(() => {
      //this.dataIsReady = true;
    });
  }
}
