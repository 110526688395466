










import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ScoreTags from "./component-score-tags.vue"
import * as _ from "lodash";

//3. 问答题（提供的答案只包含关键点的），使用en.pqan.score
@Component({
  name: "EnPqanScore",
  components: {
      ScoreTags
  }
})
export default class extends Vue {
  @Prop({ default: {} }) requestObject!: any;

  @Watch("requestObject", { immediate: true, deep: true })
  onRequestObjectChange(val: any, oldVal: any) {
    //alert("project");
    if (this.requestObject) {
      console.log(`requestObject change: ${JSON.stringify(this.requestObject)}`);
      if (!_.has(this.requestObject, "lm")) {
        /*
        _.merge(this.requestObject, {
          lm: []
        })
        */
       this.$set(this.requestObject, "lm", []);
      }
      if (!_.has(this.requestObject, "key")) {
        /*
        _.merge(this.requestObject, {
          key: []
        })
        */
       this.$set(this.requestObject, "key", []);
      }
    }
  };
}
