










import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ScoreTags from "./component-score-tags.vue"
import * as _ from "lodash";

//4. 复述和口语作文，使用en.retell.score题型
@Component({
  name: "EnRetellScore",
  components: {
    ScoreTags
  }
})
export default class extends Vue {
  @Prop({ default: {} }) requestObject!: any;

  @Watch("requestObject", { immediate: true, deep: true })
  onRequestObjectChange(val: any, oldVal: any) {
    //alert("project");
    if (this.requestObject) {
      console.log(`requestObject change: ${JSON.stringify(this.requestObject)}`);
      if (!_.has(this.requestObject, "lm")) {
        /*
        _.merge(this.requestObject, {
          lm: []
        })
        */
       this.$set(this.requestObject, "lm", []);
      }
      if (!_.has(this.requestObject, "points")) {
        /*
        _.merge(this.requestObject, {
          points: []
        })
        */
       this.$set(this.requestObject, "points", []);
      }
    }
  };
}
